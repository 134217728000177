import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

import { push } from 'connected-react-router';
import debounce from 'lodash.debounce';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { bindActionCreators } from 'redux';
import { Candidate } from 'src/models';
import {
	AddCandidate,
	selectCandidates,
	setCandidate,
	setCandidateOffline,
} from 'src/store/candidate';
import { dateCreator, dateFormat } from 'src/utils';
import FormInput from '../FormInput';

type Props = {
	className?: string | undefined;
	genders: string[];
	statuses: string[];
	candidate: Candidate;
	isEdit?: boolean;
};

export const CandidateForm: React.FC<Props> = ({
	className,
	genders,
	statuses,
	candidate,
	isEdit,
}) => {
	const ASAP = 'ASAP';

	const formRef = useRef(null);
	const [validated, setValidated] = useState(false);
	const { addToast } = useToasts();
	const candidateEmails: string[] = useSelector(selectCandidates);
	const dispatch = useDispatch();

	const updateCandidateState = bindActionCreators(
		setCandidateOffline,
		dispatch
	);
	const saveCandidateState = bindActionCreators(setCandidate, dispatch);

	const onDebounce = useMemo(
		() =>
			debounce((cddt: Candidate) => {
				if (isEdit) {
					candidate = { ...candidate, ...cddt };

					const candidateEmail = candidate.candidateEmail;
					const candidateAlreadyExists =
						candidateEmails.includes(candidateEmail);
					saveCandidateState(
						candidate,
						candidateEmail,
						candidateAlreadyExists
					);
				}
			}, 500),
		[debounce]
	);

	const handleSave = (event) => {
		const form = event.currentTarget;
		event.preventDefault();

		if (form.checkValidity() === true) {
			const candidateEmail = candidate.candidateEmail;
			dispatch(
				setCandidate(
					{ ...candidate },
					candidateEmail,
					isEdit,
					() => {
						dispatch(AddCandidate(candidateEmail));
						dispatch(push(`/candidates/${candidateEmail}`));
						setValidated(false);
					},
					(error) =>
						addToast(error ? `${error}` : 'An error occured', {
							appearance: 'error',
						})
				)
			);
		}

		setValidated(true);
	};

	const [isAvailableAsap, setIsAvailableAsap] = useState(null);

	useEffect(() => {
		if (isEdit) setIsAvailableAsap(candidate.availability === ASAP);
		else setIsAvailableAsap(true);
	}, []);

	useEffect(() => {
		if (isAvailableAsap) {
			updateCandidateState({
				...candidate,
				availability: ASAP,
			});

			if (isEdit) {
				onDebounce({
					...candidate,
					availability: ASAP,
				});
			}
		}
	}, [isAvailableAsap]);

	return (
		<article className={className}>
			<header className='mb-5'>
				<h3>Candidate</h3>
			</header>
			<section>
				<Form
					id='candidate-form'
					ref={formRef}
					noValidate
					validated={validated}
					onSubmit={handleSave}
				>
					<Row>
						<Col>
							<FormInput
								label='First name'
								required
								maxLength={50}
								fieldValue={candidate.firstName}
								setFieldValue={(value: string) =>
									updateCandidateState({
										...candidate,
										firstName: value,
									})
								}
								saveFieldvalue={(value: string) =>
									onDebounce({
										...candidate,
										firstName: value,
									})
								}
							/>
						</Col>
						<Col>
							<FormInput
								label='Last name'
								required
								maxLength={50}
								fieldValue={candidate.lastName}
								setFieldValue={(value: string) =>
									updateCandidateState({
										...candidate,
										lastName: value,
									})
								}
								saveFieldvalue={(value: string) =>
									onDebounce({
										...candidate,
										lastName: value,
									})
								}
							/>
						</Col>
					</Row>
					<Row>
						<Col sm={4}>
							<FormInput
								label='Birthday'
								type='date'
								dateFormat='dd/MM/yyyy'
								fieldValue={candidate.birthDate}
								setFieldValue={(value: Date) =>
									updateCandidateState({
										...candidate,
										birthDate: value,
									})
								}
								saveFieldvalue={(value: Date) =>
									onDebounce({
										...candidate,
										birthDate: value,
									})
								}
							/>
						</Col>
						<Col sm={4}>
							<FormInput
								label='Nationality'
								required
								maxLength={50}
								fieldValue={candidate.nationality}
								setFieldValue={(value: string) =>
									updateCandidateState({
										...candidate,
										nationality: value,
									})
								}
								saveFieldvalue={(value: string) =>
									onDebounce({
										...candidate,
										nationality: value,
									})
								}
							/>
						</Col>
						<Col sm={4}>
							<FormInput
								label='Gender'
								type='select'
								values={genders}
								required
								fieldValue={candidate.gender}
								setFieldValue={(value: string) =>
									updateCandidateState({
										...candidate,
										gender: value,
									})
								}
								saveFieldvalue={(value: string) =>
									onDebounce({ ...candidate, gender: value })
								}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<FormInput
								label='Status'
								type='select'
								values={statuses}
								required
								fieldValue={candidate.status}
								setFieldValue={(value: string) =>
									updateCandidateState({
										...candidate,
										status: value,
									})
								}
								saveFieldvalue={(value: string) =>
									onDebounce({ ...candidate, status: value })
								}
							/>
						</Col>
						<Col></Col>
					</Row>

					<Row>
						<Col>
							<FormInput
								type='check'
								label='Is available ASAP'
								checked={isAvailableAsap}
								fieldValue={isAvailableAsap}
								setFieldValue={() =>
									setIsAvailableAsap(!isAvailableAsap)
								}
							/>

							{!isAvailableAsap && (
								<FormInput
									label='Availability'
									type='date'
									required
									maxLength={50}
									fieldValue={dateCreator(
										candidate.availability
									)}
									setFieldValue={(value: Date) =>
										updateCandidateState({
											...candidate,
											availability: dateFormat(value),
										})
									}
									saveFieldvalue={(value: Date) =>
										onDebounce({
											...candidate,
											availability: dateFormat(value),
										})
									}
								/>
							)}
						</Col>
					</Row>
				</Form>
			</section>
		</article>
	);
};

export default CandidateForm;
