import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	selectExistingProfiles,
	selectSelectedProfile,
	setSelectedStoreProfile,
} from 'src/store/profile';
import {
	selectSelectedTemplate,
	setSelectedStoreTemplate,
} from 'src/store/template';

import FormInput from '../FormInput';
import IconButton from '../IconButton';
import CustomSelect from '../CustomSelect';

import GenerateModal from '../GenerateModal';
import './index.css';

export type HeaderProps = {
	buttonLabel?: string;
	icon?: JSX.Element;
	onSubmit:
		| (() => void)
		| ((
				candidateEmail: string,
				profile: string,
				template: string,
				title?: string
		  ) => void);
	isLoading?: boolean;
};

type Props = {
	profiles?: string[];
	templates: string[];
	candidateEmail: string;
	isEdit?: boolean;
} & HeaderProps;

export const HeaderForm: React.FC<Props> = ({
	profiles,
	templates,
	candidateEmail,
	onSubmit,
	buttonLabel,
	icon,
	isEdit,
	isLoading,
}) => {
	const dispatch = useDispatch();

	const updateSelectedProfile = bindActionCreators(
		setSelectedStoreProfile,
		dispatch
	);
	const updateSelectedTemplate = bindActionCreators(
		setSelectedStoreTemplate,
		dispatch
	);

	const profile = useSelector(selectSelectedProfile);
	const template = useSelector(selectSelectedTemplate);
	const existingProfiles = useSelector(selectExistingProfiles);

	const handleSubmit = (event, title = null) => {
		event.preventDefault();
		event.stopPropagation();

		onSubmit(candidateEmail, profile, template, title);
	};

	return (
		<>
			<header>
				<Form>
					<Row className='justify-content-md-center'>
						<Col sm='2' className='mb-3'>
							<FormInput
								type='email'
								placeholder='firstname.lastname@capyx.be'
								fieldValue={candidateEmail}
								isReadOnly={true}
								required
							/>
						</Col>
						{isEdit && (
							<Col sm='auto' className='mb-3'>
								<CustomSelect
									required
									values={profiles}
									existingValues={existingProfiles}
									helpText='Select a profile'
									actionOnChange={(event: {
										currentTarget: { value: string };
										nativeEvent: {};
									}) =>
										updateSelectedProfile(
											event.currentTarget.value
										)
									}
								/>
							</Col>
						)}

						{templates && templates.length > 0 && (
							<Col sm='auto' className='mb-3'>
								<FormInput
									type='select'
									required
									values={templates}
									helpText='Select a CV template'
									fieldValue={template}
									setFieldValue={(value: string) =>
										updateSelectedTemplate(value)
									}
								/>
							</Col>
						)}

						<Col sm='auto' className='text-center mb-3'>
							{buttonLabel &&
								(isEdit ? (
									<>
										{/* <CvPreviewModal /> */}
										<GenerateModal
											generateCallback={(event, title) =>
												handleSubmit(event, title)
											}
											isLoading={isLoading}
										/>
									</>
								) : (
									<IconButton
										type='submit'
										label={buttonLabel}
										isLoading={isLoading ?? false}
										icon={icon}
										className='generateCVButton'
										variant='none'
										onClick={isEdit ? handleSubmit : null}
										form={isEdit ? '' : 'candidate-form'}
									/>
								))}
						</Col>
					</Row>
				</Form>
			</header>
			<hr />
		</>
	);
};

export default HeaderForm;
