import { useState } from 'react';
import './index.css';

interface CustomSelectProps {
	required?: boolean;
	controlSize?: string;
	defaultvalue?: string | number;
	values: string[];
	existingValues?: string[];
	disabled?: boolean;
	helpText: string;
	actionOnChange?: (event: {
		currentTarget: { value: string };
		nativeEvent: {};
	}) => void;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
	required = false,
	controlSize,
	defaultvalue,
	values,
	existingValues = [],
	disabled = false,
	helpText,
	actionOnChange,
}) => {
	const [selectedValue, setSelectedValue] = useState(defaultvalue || '');
	const [isOpen, setIsOpen] = useState(false);

	const handleSelect = (value: string) => {
		setSelectedValue(value);
		setIsOpen(false);
		actionOnChange?.({
			currentTarget: { value },
			nativeEvent: {},
		});
	};

	return (
		<div
			className={`custom-select ${controlSize}`}
			tabIndex={0}
			onBlur={() => setIsOpen(false)}
		>
			<div
				className={`selected ${disabled ? 'disabled' : ''}`}
				onClick={() => !disabled && setIsOpen(!isOpen)}
			>
				{selectedValue || helpText.trim()}
			</div>

			{isOpen && (
				<ul className='options'>
					<li
						key='title'
						className='help-text'
						onClick={() => handleSelect('')}
					>
						{helpText.trim()}
					</li>
					{values.map((val, index) => {
						const isBold = existingValues.includes(val);
						return (
							<li
								key={index}
								className={isBold ? 'star-icon' : ''}
								style={isBold ? { fontWeight: 'bold' } : {}}
								onClick={() =>
									handleSelect(val.replace(/ /g, ''))
								}
							>
								{val.trim()}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default CustomSelect;
